import React from 'react';
import styled from 'styled-components';

import celebrate from 'assets/celebrate-thrive.svg';
import CustomButton from 'common/CustomButton';
import LinkButton from 'common/LinkButton'

export default React.memo(function ThriveThankYouForm() {
  const redirectHandler = React.useCallback(() => {
    window.location.href = `${process.env.REACT_APP_ENV_URL}/login`;
  }, []);

  return (
    <StyledWrapper>
      <div className='form-container'>
        <img
          className='icon'
          src={celebrate}
          alt='Celebrate'
        />
        <p className='title'>Welcome to Thrive!</p>
        <p className='sub-title'>Your password has been sent to your email address.</p>
        <div>
          <CustomButton
            className='yellow-button'
            clickHandler={redirectHandler}
            aria-label="Access your Thrive dashboard"
          >
            Go to Dashboard
          </CustomButton>

          <div style={{ marginTop: '16px' }}>
            <LinkButton
              clickHandler={() => window.open('https://cibahealth.typeform.com/to/AQ1KqB59', '_blank')}
              aria-label="Invite friends to join Thrive"
            >
              Invite Your Friends
            </LinkButton>
          </div>
        </div>
      </div>

    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: relative;
  margin-left: 50%;
  width: 50%;
  padding: 128px 64px;
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    padding: 24px 20px;
    box-sizing: border-box;
  }

  .yellow-button {
    background-color: #F38744;
    border: none;
    padding: 12px 40px;
  }

  .icon {
    width: 100px;
    height: 100px;
  }

  .title {
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }

  .sub-title {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(30, 27, 75, 0.80);
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 64px;
    max-width: 600px;

    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }
`;