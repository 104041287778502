import React from 'react'
import styled from 'styled-components'
import thriveJoinImage from 'assets/thrive-join-750.jpeg'

import LeftSideHeader from './ThriveLeftSideHeader'

export default React.memo(function ThriveJoinLeftSide() {
  const subTitle = 'Ditch your doctor and Band-aid fixes. Take control of your health and address the root causes of fatigue, weight issues, chronic pain, and more.'
  return (
    <StyledWrapper>
      <div className='left-col'>
        <LeftSideHeader />
        <Image role="img" aria-label="Thrive wellness program promotional image" />
        <div className='main-text'>
          <p className='sub-title'>{subTitle}</p>
        </div>
        <div />
      </div>

    </StyledWrapper>
  )
}, () => true)


const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  justify-content: space-between;
  width: 50%;
  background-color: #45356E;
  text-align: center;
  padding: 128px 64px;

  @media screen and (max-width: 1440px) {
    padding: 64px;
  }
  
  @media screen and (max-width: 1024px) {
    padding: 48px;
  }
  
  @media screen and (max-width: 768px) {
    position: static;
    width: 100%;
    padding: 24px 20px;
    box-sizing: border-box;
    height: auto;
  }

    @media screen and (max-width: 375px) {
    padding: 0;
  }

  .left-col {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0 64px;
    @media screen and (max-width: 1024px) {
     padding: 80px 48px;
    }
     @media screen and (max-width: 768px) {
      padding: 48px 24px;
    }
     @media screen and (max-width: 375px) {
      padding: 24px 16px;
    }
  }

  .main-text {
    color: #fff;
    font-weight: 400;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    
    .sub-title {
      color: #fff;
      font-size: 20px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
`

const Image = styled.div`
  width: 100%;
  max-width: 592px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background: url(${thriveJoinImage}) lightgray 50% / cover no-repeat;
  border-radius: 32px;
  border: 10px solid rgba(255, 255, 255, 0.50);
  aspect-ratio: 5 / 4;
  @media screen and (max-width: 1024px) {
    max-width: 420px;
    aspect-ratio: 4 / 3;

  }

  @media screen and (max-width: 768px) {
    max-width: 416px;
    aspect-ratio: 16 / 9;
  }

  @media screen and (max-width: 375px) {
    max-width: 343px;
  }
`

