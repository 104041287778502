import React from 'react';
import { useLocation } from 'react-router-dom';
import { JibTheme } from 'themes/jib';
import jibElement from 'assets/jib_element.svg';
import alignedElement from 'assets/aligned_element.svg';
import { ReactComponent as Logo } from 'assets/logo-dark.svg';
import { Widget } from '@typeform/embed-react';
import createNotification from 'utils/createNotification';
import EnrolmentFooter from './EnrolmentFooter';
import FamilyImage from 'assets/frame11_family.jpeg';
import AlignedIllustration from 'assets/aligned_illustration.jpg';
import magnacareLogo from 'assets/magnacare_logo.png';
import alignedLogo from 'assets/aligned_logo.svg';
import { COMPANY, MAGNACARE, JIB, ALIGNED } from 'config/routes'

export default function Questionnaire({ formId }) {
  const [showIframe, setShowIframe] = React.useState(false);

  const location = useLocation();

  const isJib = location.pathname?.startsWith(`/${COMPANY}/${MAGNACARE}/${JIB}`) ?? false;
  const isAligned = location.pathname?.startsWith(`/${COMPANY}/${ALIGNED}`) ?? false;

  const onStart = React.useCallback(() => {
    setShowIframe(true);
  }, []);

  React.useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        onStart();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onStart]);

  const handleTypeformSubmit = () => {
    createNotification({
      title: 'Thank You',
      message: 'Your form has been successfully submitted to Ciba Health.',
      type: 'success',
    });
  };

  const renderLeftSection = () => {
    let backgroundImage = `url(${jibElement})`;
    let illustrationImage = FamilyImage;

    if (isAligned) {
      backgroundImage = `url(${alignedElement})`;
      illustrationImage = AlignedIllustration;
    }

    return (
      <div className="w-full md:w-[42.5%] bg-deepPurple950 flex items-center h-full">
        <div
          className="w-full py-3 md:py-60 mx-auto text-center max-w-[800px]"
          style={{
            background: `${backgroundImage} no-repeat center center`,
            backgroundSize: 'contain',
          }}
        >
          <div className="max-w-2xl mx-auto p-12 lg:p-0">
            <img
              src={illustrationImage}
              alt="People enjoying healthy lifestyle"
              className="w-[500px] self-stretch mb-8 rounded-[20px]"
            />
            <p className="text-xl font-bold text-white mb-8">
              Begin Your Wellness Journey Today!
            </p>
            {!showIframe && (
              <p className="text-baseRelaxed text-white">
                We've helped thousands of people just like you prevent and reverse
                common and complex health conditions. Ready to start your wellness journey?
                Complete this brief questionnaire to get started—100% of the cost is
                covered by your employer.
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderRightSection = () => {
    return (
      <div className="gap-[2px] mx-auto">
        <p className="text-[32px] font-bold text-deepPurple950">
          Health Questionnaire
        </p>
        <p className="text-[16px] mb-[24px]">
          Takes less than 2 minutes to complete
        </p>

        <div className="flex flex-col md:flex-row items-center gap-[16px] pl-[0] md:pl-[64px]">
          <button
            onClick={onStart}
            className="bg-deepPurple900 hover:bg-deepPurple950 border-transparent disabled:bg-gray-100 w-full md:w-[166px] py-[11px] text-white rounded-full text-[16px] font-semibold focus:shadow-lightBlue100"
            aria-label="Start health questionnaire"
          >
            Start Now
          </button>
          <div className="hidden md:flex gap-[8px] items-center mt-4 md:mt-0">
            <div className="text-[#3D3D3D] font-semibold text-sm">Press Enter</div>
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M10.6668 6.21193L10.6669 0.211995L9.33357 0.211975L9.3335 4.87859L2.5523 4.87866L5.18543 2.24549L4.24264 1.30268L0 5.54533L4.24264 9.788L5.18543 8.84513L2.55227 6.212L10.6668 6.21193Z"
                fill="#3D3D3D"
              />
            </svg>
          </div>
        </div>
        <div className="w-full mx-auto flex justify-center items-center text-center mt-[24px]">
          <p className="text-[14px]">
            Already enrolled?
          </p>
          <a href={`${process.env.REACT_APP_ENV_URL}/login`} className="mx-4 text-[14px] font-semibold text-deepPurple700 underline underline-offset-[5px]" aria-label="Log in to your existing account">
            Log in here
          </a>
        </div>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className="w-full h-[80vh] md:h-[75vh] flex items-center justify-center">
        <Widget
          id={formId}
          autoFocus={true}
          className="w-full h-full"
          onSubmit={handleTypeformSubmit}
          inlineOnMobile={true}
        />
      </div>
    )
  }

  const renderCompanyLogo = () => {
    if (isJib) {
      return <img src={magnacareLogo} alt="Magnacare Logo" className="w-[150px] lg:w-[210px] mx-auto" />;
    }
    if (isAligned) {
      return <img src={alignedLogo} alt="Aligned Marketplace Logo" className="w-[100px] lg:w-[128px] mx-auto" />;
    }
    return null;
  };

  return (
    <JibTheme>
      <div className="min-h-screen mx-auto flex flex-col md:flex-row">
        <div className="flex flex-col-reverse md:flex-row w-full justify-between gap-[48px] md:gap-0">
          {renderLeftSection()}
          <div className="flex h-full w-full md:w-[57.5%] flex-col mx-auto text-center my-auto justify-between pt-12 px-6 md:px-0 gap-12">
            <div className='flex flex-col lg:flex-row gap-1 lg:gap-[24px] mx-auto'>
              <Logo className="w-[145px] lg:w-[194px] mx-auto" />
              {renderCompanyLogo()}
            </div>
            {!showIframe ? renderRightSection() : renderForm()}
            <div className='hidden md:flex'>
              <EnrolmentFooter />
            </div>
          </div>
        </div>
        <div className='flex md:hidden'>
          <EnrolmentFooter />
        </div>
      </div>
    </JibTheme>
  );
}