import React from 'react'
import styled from 'styled-components'

const planData = {
  details: [
    { text: "Access to 100+ advanced lab tests not typically ordered by conventional doctors" },
    { text: "Personalized recommendations and action plans from doctors specialized in root-cause medicine" },
    { text: "Live educational webinars with certified registered dietitians" },
    { text: "Comprehensive library of nutritious, easy-to-prepare recipes" },
    { text: "Follow-up lab testing every 6 months to track your progress" },
    { text: "Option to upgrade your membership for one-on-one consultations with doctors, dietitians, and health coaches" },
    { text: "24/7 access to real-time health insights from your lab results and connected wearable devices" },
  ]
}

const Detail = React.memo(function Detail({ text }) {
  return (
    <StyledItem>
      <span className='icon'>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" aria-label="Included feature checkmark">
          <circle cx="10" cy="10" r="10" fill="#F38744" />
          <path d="M6.68164 9.99929L8.89441 12.2972L13.3199 7.70142" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </span>
      <span className='text'>{text}</span>
    </StyledItem>
  )
})

export default React.memo(function Details() {
  const details = React.useMemo(() => {
    if (!planData) return null
    const parsedDetails = planData.details.map(({ text }, i) => {
      return (
        <Detail
          key={i}
          text={text}
        />
      )
    })
    return (
      <StyledList>
        {parsedDetails}
        <p className="description">
          Need a more flexible payment plan? Please
          <a href="https://meetings.hubspot.com/llynch1/thrive-payment-plan" target="_blank" rel="noopener noreferrer" aria-label="Schedule a call about flexible payment options">schedule a call</a>
          with a Ciba Health support specialist to discuss your options.
        </p>
      </StyledList>
    )
  }, [])

  return details
})

const StyledItem = styled.li`
  position: relative;
  display: flex;
    .icon {
    margin-right: 1rem;
  }
  .text {
   color: #1E1B4B;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; 
  }
  .tooltip {
    position: absolute;
    bottom: 25px;
    left: 0;
    border-radius: 2px;
    border: solid 1px #e0e0e0;
    background-color: #f9f9f9;
    padding: 10px 17px 14px;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    transition: all .35s ease;
    opacity: 0;
    z-index: -1;
  }
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--2, 8px);
  align-self: stretch;
   
  .description {
    margin-top: 8px;
    color: rgba(30, 27, 75, 0.80);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  a {
    color: #4338CA;
    margin-left: 4px;
    margin-right: 4px;
    text-decoration: underline;
  }
`

